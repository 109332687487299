import huerto1 from "./images/HUERTO/1.jpg";
import huerto2 from "./images/HUERTO/2.jpg";
import huerto3 from "./images/HUERTO/3.jpg";
import huerto4 from "./images/HUERTO/4.jpg";
import huerto5 from "./images/HUERTO/5.jpg";
import huerto6 from "./images/HUERTO/6.jpg";
import huerto7 from "./images/HUERTO/7.jpg";
import huerto8 from "./images/HUERTO/8.jpg";

export const huerto = [
  {
    url: huerto1,
  },
  {
    url: huerto2,
  },
  {
    url: huerto3,
  },
  {
    url: huerto4,
  },
  {
    url: huerto5,
  },
  {
    url: huerto6,
  },
  {
    url: huerto7,
  },  {
    url: huerto8,
  },
];
