import exterior1 from "./images/EXTERIORES/patio exterior/1.jpg";
import exterior2 from "./images/EXTERIORES/patio exterior/2.jpg";
import exterior3 from "./images/EXTERIORES/patio exterior/3.jpg";
import exterior4 from "./images/EXTERIORES/patio exterior/4.jpg";
import exterior5 from "./images/EXTERIORES/patio exterior/5.jpg";
import exterior6 from "./images/EXTERIORES/patio exterior/6.jpg";
import exterior7 from "./images/EXTERIORES/patio exterior/7.jpg";

export const exterior = [
  {
    url: exterior1,
  },
  {
    url: exterior2,
  },
  {
    url: exterior3,
  },
  {
    url: exterior4,
  },
  {
    url: exterior5,
  },
  {
    url: exterior6,
  },
  {
    url: exterior7,
  },
];
