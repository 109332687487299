import piscina1 from "./images/EXTERIORES/PISCINA/1.jpg";
import piscina2 from "./images/EXTERIORES/PISCINA/2.jpg";
import piscina3 from "./images/EXTERIORES/PISCINA/3.jpg";
import piscina4 from "./images/EXTERIORES/PISCINA/4.jpg";
import piscina5 from "./images/EXTERIORES/PISCINA/5.jpg";
import piscina6 from "./images/EXTERIORES/PISCINA/6.jpg";
import piscina7 from "./images/EXTERIORES/PISCINA/7.jpg";

export const piscina = [
  {
    url: piscina1,
  },
  {
    url: piscina2,
  },
  {
    url: piscina3,
  },
  {
    url: piscina4,
  },
  {
    url: piscina5,
  },
  {
    url: piscina6,
  },
  {
    url: piscina7,
  },
];
