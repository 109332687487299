import * as React from "react";
import Footer from "../components/Footer/footer";
import { Head } from "../components/Header/head";
import { Navbar } from "../components/Navbar/navbar";
import "../styles/posada.css";
import carousel1 from "../assets/images/EXTERIORES/patio principal/2.jpg";
import carousel2 from "../assets/images/EXTERIORES/RINCON MAGDALENA/4.jpg";
import carousel3 from "../assets/images/EXTERIORES/RINCON MAGDALENA/5.jpg";
import { exterior } from "../assets/exteriores_patio_exterior";
import { interior } from "../assets/exteriores_patio_interior";

import ModalCarousel from "../components/ModalCarousel/ModalCarousel";
import { piscina } from "../assets/exteriores_piscina";
import { rincon } from "../assets/exteriores_patio_rincon";
import { Image } from "react-bootstrap";
import Slider from "react-slick";
import { huerto } from "../assets/huerto";
import { animales } from "../assets/animales";
import { SSRProvider } from "@react-aria/ssr";

const Posada = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };
  const sliders = (arrayFotos) => {
    return arrayFotos.map((image, index) => {
      return <Image key={index} src={image.url} />;
    });
  };
  const [show, setShow] = React.useState(true);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <SSRProvider>
      <Head title="Posada Los Jerónimos - Posada" />
      <Navbar />

      <section className="carousel-wrapper">
        <div
          id="carouselExampleControls"
          className="carousel slide"
          data-ride="carousel"
        >
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img src={carousel1} className="d-block w-100" alt="..." />
            </div>
            <div className="carousel-item">
              <img src={carousel2} className="d-block w-100" alt="..." />
            </div>
            <div className="carousel-item">
              <img src={carousel3} className="d-block w-100" alt="..." />
            </div>
          </div>
          <a
            className="carousel-control-prev"
            href="#carouselExampleControls"
            role="button"
            data-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="sr-only">Previous</span>
          </a>
          <a
            className="carousel-control-next"
            href="#carouselExampleControls"
            role="button"
            data-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="sr-only">Next</span>
          </a>
        </div>
      </section>
      <section id="historia" className="historia">
        <h2>Historia</h2>
        <p>
          Esta casa fue un cenobio perteneciente a la orden de Los Jerónimos
          desde finales del siglo XVII hasta la desamortización de Mendizábal de
          1850.
        </p>
        <p>
          Fue entonces cuando se dividió en cinco partes y pasó a manos de
          familias particulares. Hasta la época de la emigración de las zonas
          rurales en que quedó deshabitada. En el año 1998, compramos dos de
          aquellas partes y nos pusimos manos a la obra de la restauración de
          las ruinas que encontramos.
        </p>
        <p>
          Después de darle muchas vueltas a la cabeza decidimos hacer una casa
          rural... Papeleos, proyecto visado por arquitecto, subvenciones, pedir
          un crédito a un banco, burocrocia pura y dura.{" "}
        </p>
        <p>
          {" "}
          No teníamos luz ni desagüe. Lo que sí que teníamos era mucha piedra.
          Empezamos sin saber donde nos metíamos, echando muchas horas, y sobre
          todo el mucho trabajo de Braulio, hemos construido lo que hoy día es
          Los Jerónimos. 21 años después hemos adquirido todo lo que fue el
          cenobio y restaurado según se ha ido pudiendo, poco a poco, piedra a
          piedra, aún queda proyecto y ganas de seguir haciendo.{" "}
        </p>
        <p>
          {" "}
          Conseguimos hacer nuestra casa y un negocio al mismo tiempo, una
          manera de vivir en el campo pero con mucha comunicación directa con el
          resto del mundo, gracias sobre todo a las conversaciones siempre
          amigables con las personas que nos visitan.{" "}
        </p>
        <p>
          Aunque en La Tuda la vida es tranquila, cuando no hay clientes,
          siempre hay trabajo por hacer, cuidar de los animales, regar las
          plantas, limpieza y mantenimiento de la casa por dentro y por fuera,
          más alguna obra pendiente de hacer.{" "}
        </p>
        <p>
          Por favor, si ven alguna zona en obras o con desorden, disculpen las
          molestias.
        </p>
      </section>
      <section id="exteriores" className="exteriores">
        <h2>Exteriores</h2>
        <p>
          Con el suelo empedrado es especialmente bonito en verano por la
          abundancia de plantas con flores, un estanque con peces y ranas, y una
          cascada hecha de tejas. A partir de la pandemia del Covid, se ha
          realizado una parte del patio como terraza comedor, con una buena
          sombra gracias a la glicinia, que tiene una muy bonita floración entre
          abril y mayo. Además la parte de la cumbre, también puede servir como
          zona de terraza o para jugar al tenis de mesa.
        </p>
        <p>
          <b>Contamos con cargador de vehículos eléctricos.</b>
        </p>
        <div className="cards-wrapper">
          <div className="card-exteriores">
            <div className="img-wrp">
              <ModalCarousel images={exterior} portada={exterior[5].url} />
            </div>
            <h3>Patio exterior</h3>
          </div>
          <div className="card-exteriores">
            <div className="img-wrp">
              <ModalCarousel images={interior} portada={interior[13].url} />
            </div>
            <h3>Patio principal</h3>
          </div>

          <div className="card-exteriores">
            <div className="img-wrp">
              <ModalCarousel images={rincon} portada={rincon[4].url} />
            </div>
            <div>
              <h3>Rincon de Magdalena</h3>
            </div>
          </div>
        </div>
      </section>
      <section id="piscina" className="piscina">
        <h2>Piscina</h2>
        <p>
          En el 2017, decidimos poner una piscina, decisión muy discutida, pero
          al final se llegó a un punto medio, ponerla, pero no muy grande y con
          una zona de plantas que ayudan a una limpieza más natural y a una
          mejor integración con el entorno. Mucho trabajo, pero 4 años después,
          creemos que ha sido un acierto, el duro calor de los meses de verano
          es mucho más llevadero si tienes una zona de baño para refrescarte.
        </p>
        <p>
          Hecha de obra por Braulio como maestro albañil y Pedro Lucas como
          arquitecto, es uno de los atractivos de la época estival. No es muy
          grande, 10 x 4 m aprox. Y 1,5 m de profundidad. Consta también de un
          estanque de plantas por donde recircula el agua haciendo de filtro
          natural.
        </p>
        <p>
          El rincón de Magdalena es un espacio anexo a la piscina con sillas y
          mesas para quien prefiera estar a la sombra vigilando a sus hijos en
          la piscina.
        </p>
        <p>
          IMPORTANTE: El uso de la piscina es privado, no tenemos socorrista, ni
          cobramos por su uso y disfrute. Es por ello que podemos prohibir su
          uso a aquellas personas que no cuiden a sus hijos permanentemente o no
          respeten horarios, etc.
        </p>
        <p>
          Con esta iniciativa queremos hacer de vuestra estancia una experiencia
          más completa y especial.
        </p>
        <div className="sliderWrapper">
          <Slider {...settings}>{sliders(piscina)}</Slider>
        </div>
      </section>
      <section className="cita">
        <p>
          Buen comer y beber, disfrutar de la naturaleza, de la vida rural y,
          por qué no, de un buen chapuzón. Lujo asiático, que diría un amigo
          mío.
        </p>
      </section>
      <section id="huerto" className="huerto">
        <h2>Huerto Ecológico</h2>
        <p>
          Nuestro huerto ecológico es sin duda uno de nuestros mayores tesoros.
        </p>
        <p>
          Gracias a él, damos auténtico sabor a la mayoría de los platos del
          restaurante. En ciertas temporadas del año (julio a octubre), una
          parte de lo que ponemos es literalmente “de la huerta a la mesa”. El
          resto del año, aprovechamos mucho la producción sobrante que hemos
          conservado de distintas maneras.
        </p>
        <p>
          {" "}
          Estamos siempre dispuestos y encantados de enseñar nuestro huerto a
          quien lo desee, como la mejor prueba de que lo hacemos de manera 100%
          ecológica, responsable y sostenible.{" "}
        </p>
        <p>
          Tenemos en cuenta la rotación de los cultivos, la asociación de los
          cultivos, la biodiversidad de plantas con flores, el ahorro del agua,
          el abonado con compost propio, etc.
        </p>
        <div className="sliderWrapper">
          <Slider {...settings}>{sliders(huerto)}</Slider>
        </div>
      </section>
      <section id="animales" className="animales">
        <h2>Animales</h2>
        <div className="sliderWrapper">
          <Slider {...settings}>{sliders(animales)}</Slider>
        </div>
      </section>
      <Footer slim />
    </SSRProvider>
  );
};

export default Posada;
