import interior1 from "./images/EXTERIORES/patio principal/1.jpg";
import interior2 from "./images/EXTERIORES/patio principal/2.jpg";
import interior3 from "./images/EXTERIORES/patio principal/3.jpg";
import interior4 from "./images/EXTERIORES/patio principal/4.jpg";
import interior5 from "./images/EXTERIORES/patio principal/5.jpg";
import interior6 from "./images/EXTERIORES/patio principal/6.jpg";
import interior7 from "./images/EXTERIORES/patio principal/7.jpg";
import interior8 from "./images/EXTERIORES/patio principal/8.jpg";
import interior9 from "./images/EXTERIORES/patio principal/9.jpg";
import interior10 from "./images/EXTERIORES/patio principal/10.jpg";
import interior11 from "./images/EXTERIORES/patio principal/11.jpg";
import interior12 from "./images/EXTERIORES/patio principal/12.jpg";
import interior13 from "./images/EXTERIORES/patio principal/13.jpg";
import interior14 from "./images/EXTERIORES/patio principal/14.jpg";
import interior15 from "./images/EXTERIORES/patio principal/15.jpg";
import interior16 from "./images/EXTERIORES/patio principal/16.jpg";
import interior17 from "./images/EXTERIORES/patio principal/17.jpg";
import interior18 from "./images/EXTERIORES/patio principal/18.jpg";
import interior19 from "./images/EXTERIORES/patio principal/19.jpg";

export const interior = [
  {
    url: interior1,
  },
  {
    url: interior2,
  },
  {
    url: interior3,
  },
  {
    url: interior4,
  },
  {
    url: interior5,
  },
  {
    url: interior6,
  },
  {
    url: interior7,
  },
  {
    url: interior8,
  },
  {
    url: interior9,
  },
  {
    url: interior10,
  },
  {
    url: interior11,
  },
  {
    url: interior12,
  },
  {
    url: interior13,
  },
  {
    url: interior14,
  },
  {
    url: interior15,
  },
  {
    url: interior16,
  },
  {
    url: interior17,
  },
  {
    url: interior18,
  },
  {
    url: interior19,
  },
];
