import rincon1 from "./images/EXTERIORES/RINCON MAGDALENA/1.jpg";
import rincon2 from "./images/EXTERIORES/RINCON MAGDALENA/2.jpg";
import rincon3 from "./images/EXTERIORES/RINCON MAGDALENA/3.jpg";
import rincon4 from "./images/EXTERIORES/RINCON MAGDALENA/4.jpg";
import rincon5 from "./images/EXTERIORES/RINCON MAGDALENA/5.jpg";
import rincon6 from "./images/EXTERIORES/RINCON MAGDALENA/6.jpg";
import rincon7 from "./images/EXTERIORES/RINCON MAGDALENA/7.jpg";

export const rincon = [
  {
    url: rincon1,
  },
  {
    url: rincon2,
  },
  {
    url: rincon3,
  },
  {
    url: rincon4,
  },
  {
    url: rincon5,
  },
  {
    url: rincon6,
  },
  {
    url: rincon7,
  },
];
