import animales1 from "./images/ANIMALES/1.jpg";
import animales2 from "./images/ANIMALES/2.jpg";
import animales3 from "./images/ANIMALES/3.jpg";
import animales4 from "./images/ANIMALES/4.jpg";
import animales5 from "./images/ANIMALES/5.jpg";
import animales6 from "./images/ANIMALES/6.jpg";
import animales7 from "./images/ANIMALES/7.jpg";
import animales8 from "./images/ANIMALES/8.jpg";
import animales9 from "./images/ANIMALES/9.jpg";
import animales10 from "./images/ANIMALES/10.jpg";
import animales11 from "./images/ANIMALES/11.jpg";
import animales12 from "./images/ANIMALES/12.jpg";
import animales13 from "./images/ANIMALES/13.jpg";
import animales14 from "./images/ANIMALES/14.jpg";
import animales15 from "./images/ANIMALES/15.jpg";
import animales16 from "./images/ANIMALES/16.jpg";
import animales17 from "./images/ANIMALES/17.jpg";
import animales18 from "./images/ANIMALES/18.jpg";
import animales19 from "./images/ANIMALES/19.jpg";
import animales20 from "./images/ANIMALES/20.jpg";
export const animales = [
  {
    url: animales1,
  },
  {
    url: animales2,
  },
  {
    url: animales3,
  },
  {
    url: animales4,
  },
  {
    url: animales5,
  },
  {
    url: animales6,
  },
  {
    url: animales7,
  },
  {
    url: animales8,
  },
  {
    url: animales9,
  },
  {
    url: animales10,
  },
  {
    url: animales11,
  },
  {
    url: animales12,
  },
  {
    url: animales13,
  },
  {
    url: animales14,
  },
  {
    url: animales15,
  },
  {
    url: animales16,
  },
  {
    url: animales17,
  },
  {
    url: animales18,
  },
  {
    url: animales19,
  },
  {
    url: animales20,
  },
];
